var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "informationForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "标题",
                        prop: "title",
                        rules: [
                          {
                            required: true,
                            message: "标题不允许为空",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入标题",
                          maxlength: 30,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "封面图" } },
                    [
                      _c("v-uploader", {
                        attrs: {
                          action: _vm.uploadURL,
                          imgUrls: _vm.form.icon,
                          fileSize: 0.5,
                          imageWH: [210, 210],
                        },
                        on: {
                          "update:imgUrls": function ($event) {
                            return _vm.$set(_vm.form, "icon", $event)
                          },
                          "update:img-urls": function ($event) {
                            return _vm.$set(_vm.form, "icon", $event)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "tip",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(
                                    "注：仅支持宽210px高210px，图片小于512KB"
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm.isAdd
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属App",
                            rules: [
                              {
                                required: true,
                                message: "所属App不能为空",
                                trigger: "change",
                              },
                            ],
                            prop: "appType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.appTypeOps,
                              width: _vm.width,
                            },
                            on: { change: _vm.appTypeChange },
                            model: {
                              value: _vm.form.appType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "appType", $$v)
                              },
                              expression: "form.appType",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("el-form-item", { attrs: { label: "所属App" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.appTypeMap[_vm.detailItem.appType])
                          ),
                        ]),
                      ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "类别",
                        rules: [
                          {
                            required: true,
                            message: "类别不能为空",
                            trigger: "change",
                          },
                        ],
                        prop: "type",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.typeOps, width: _vm.width },
                        on: { change: _vm.typeChange },
                        model: {
                          value: _vm.form.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "子类别",
                        rules: [
                          {
                            required: true,
                            message: "子类别不能为空",
                            trigger: "change",
                          },
                        ],
                        prop: "sectype",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.sectypeOps, width: _vm.width },
                        model: {
                          value: _vm.form.sectype,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sectype", $$v)
                          },
                          expression: "form.sectype",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "排序",
                        prop: "sort",
                        rules: [
                          {
                            required: true,
                            message: "请输入排序",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          max: 99999999,
                          min: 0,
                          width: _vm.width,
                          placeholder: "请输入排序",
                          controls: "",
                          controlsPosition: "right",
                        },
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "关键字",
                        prop: "keywords",
                        rules: [
                          {
                            type: "array",
                            required: true,
                            message: "请输入关键字",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "keyword-wrapper" },
                        [
                          _vm._l(_vm.form.keywords, function (keyword, index) {
                            return _c(
                              "el-tag",
                              {
                                key: `${keyword}**${index}`,
                                staticClass: "tag-keyword",
                                attrs: {
                                  closable: "",
                                  "disable-transitions": false,
                                },
                                on: {
                                  close: function ($event) {
                                    return _vm.removeKeyword(index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(keyword))]
                            )
                          }),
                          _c(
                            "div",
                            [
                              _vm.inputVisible
                                ? _c("v-input", {
                                    ref: "saveTagInput",
                                    staticClass: "input-keyword",
                                    attrs: {
                                      maxlength: 20,
                                      placeholder: "请输入关键字",
                                      width: _vm.width,
                                    },
                                    on: { blur: _vm.handleInputConfirm },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.handleInputConfirm.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.inputValue,
                                      callback: function ($$v) {
                                        _vm.inputValue = $$v
                                      },
                                      expression: "inputValue",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _vm.form.keywords.length < 3
                                ? _c("v-button", {
                                    staticClass: "input-keyword",
                                    attrs: { text: "+ 添加关键字" },
                                    on: { click: _vm.showInput },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c("div", [
                        _vm._v(
                          "注：请输入关键字（最多3个），便于信息检索和分享"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "资讯信息" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "跳转类型", required: "" } },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          options: _vm.skipTypeOps,
                          value: _vm.form.skipType,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "skipType", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "el-form-div-wrapper" },
                    [
                      _vm.form.skipType === 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "内容",
                                prop: "content",
                                rules: [
                                  {
                                    required: true,
                                    message: "输入内容不能为空",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-ueditor", {
                                ref: "ueditor",
                                model: {
                                  value: _vm.form.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "content", $$v)
                                  },
                                  expression: "form.content",
                                },
                              }),
                              _vm.form.appType !== 1
                                ? _c(
                                    "div",
                                    { staticClass: "choose-jump-wrapper" },
                                    [
                                      _c("jump-go", {
                                        on: { callback: _vm.jumpCallback },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.form.skipType === 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "跳转页面",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.jumpNameValidator,
                                    trigger: "change",
                                  },
                                ],
                                prop: "skipData",
                              },
                            },
                            [
                              _c("jump-go", {
                                attrs: { btnText: _vm.jumpName, skipType: 2 },
                                on: { callback: _vm.jumpFunCallback },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.form.skipType === 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "外部链接",
                                prop: "outerUrl",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入外部链接",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content-wrapper" },
                                [
                                  _c("v-input", {
                                    attrs: {
                                      placeholder: "外部链接URL地址",
                                      width: _vm.width,
                                      maxlength: 200,
                                    },
                                    model: {
                                      value: _vm.form.outerUrl,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "outerUrl", $$v)
                                      },
                                      expression: "form.outerUrl",
                                    },
                                  }),
                                  _c("span", { staticClass: "right-tip" }, [
                                    _vm._v(
                                      '注：请填写以"http://"或"https://"开头的URL地址\n              '
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.showLabelSelect
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "关联组织标签",
                            prop: "firstOrgTagList",
                            rules: [
                              {
                                type: "array",
                                required: _vm.isAdd,
                                message: "关联组织标签不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("checkbox-plus", {
                            attrs: {
                              type: "default",
                              options: _vm.labelList,
                              mode: "checkbox-plus",
                              value: _vm.form.firstOrgTagList,
                              disabled: !_vm.isAdd,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "firstOrgTagList",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isAdd
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "发布项目",
                            prop: "communityIds",
                            rules: [
                              {
                                type: "array",
                                required: true,
                                message: "发布项目不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("chosenListPanel", {
                            attrs: { list: _vm.form.communityIds },
                            on: {
                              "update:list": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "communityIds",
                                  $event
                                )
                              },
                              select: function ($event) {
                                _vm.selectCommunityShow = true
                              },
                              change: _vm.selectCommunityChange,
                            },
                          }),
                        ],
                        1
                      )
                    : _c("el-form-item", { attrs: { label: "发布项目" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.detailItem.communityName)),
                        ]),
                      ]),
                ],
                1
              ),
              !_vm.isAdd
                ? _c(
                    "col2-block",
                    { attrs: { title: "操作信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "状态",
                            rules: [
                              {
                                required: true,
                                message: "请选择状态",
                                trigger: "change",
                              },
                            ],
                            prop: "status",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.statusOps, width: _vm.width },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("multi-select", {
                attrs: {
                  title: "标签列表",
                  isShow: _vm.selectTagShow,
                  searchUrl: _vm.getOrgTagSearchListURL,
                  headers: _vm.orgTagTableHeader,
                  searchParams: _vm.orgTagSearchParams,
                  responseParams: _vm.orgTagResponseParams,
                  responseKey: _vm.responseKey,
                  backFill: _vm.form.orgTagIds,
                },
                on: {
                  "update:isShow": function ($event) {
                    _vm.selectTagShow = $event
                  },
                  "update:is-show": function ($event) {
                    _vm.selectTagShow = $event
                  },
                  "update:backFill": function ($event) {
                    return _vm.$set(_vm.form, "orgTagIds", $event)
                  },
                  "update:back-fill": function ($event) {
                    return _vm.$set(_vm.form, "orgTagIds", $event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchSlot",
                    fn: function () {
                      return [
                        _c("v-input", {
                          attrs: { label: "标签名称" },
                          model: {
                            value: _vm.orgTagSearchParams.tagName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orgTagSearchParams, "tagName", $$v)
                            },
                            expression: "orgTagSearchParams.tagName",
                          },
                        }),
                        _c(
                          "v-select2",
                          _vm._b(
                            {
                              attrs: { label: "上级标签" },
                              model: {
                                value: _vm.orgTagSearchParams.parentId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.orgTagSearchParams,
                                    "parentId",
                                    $$v
                                  )
                                },
                                expression: "orgTagSearchParams.parentId",
                              },
                            },
                            "v-select2",
                            _vm.superiorTagParams,
                            false
                          )
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("multi-select", {
                attrs: {
                  title: "项目列表",
                  isShow: _vm.selectCommunityShow,
                  searchUrl: _vm.getCommunityListURL,
                  headers: _vm.communityTableHeader,
                  searchParams: _vm.communitySearchParams,
                  responseParams: _vm.communityResponseParams,
                  responseKey: _vm.responseKey,
                  backFill: _vm.form.communityIds,
                },
                on: {
                  "update:isShow": function ($event) {
                    _vm.selectCommunityShow = $event
                  },
                  "update:is-show": function ($event) {
                    _vm.selectCommunityShow = $event
                  },
                  "update:backFill": function ($event) {
                    return _vm.$set(_vm.form, "communityIds", $event)
                  },
                  "update:back-fill": function ($event) {
                    return _vm.$set(_vm.form, "communityIds", $event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchSlot",
                    fn: function () {
                      return [
                        _c("v-input", {
                          attrs: { label: "项目名称" },
                          model: {
                            value: _vm.communitySearchParams.communityName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "communityName",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.communityName",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "区域类型",
                            options: _vm.searchRegionTypeOps,
                          },
                          model: {
                            value: _vm.communitySearchParams.searchRegionType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "searchRegionType",
                                $$v
                              )
                            },
                            expression:
                              "communitySearchParams.searchRegionType",
                          },
                        }),
                        _c(
                          "v-select2",
                          _vm._b(
                            {
                              attrs: {
                                label: "所属区域",
                                subjoin: {
                                  regionType:
                                    _vm.communitySearchParams.searchRegionType,
                                },
                              },
                              model: {
                                value: _vm.communitySearchParams.regionId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.communitySearchParams,
                                    "regionId",
                                    $$v
                                  )
                                },
                                expression: "communitySearchParams.regionId",
                              },
                            },
                            "v-select2",
                            _vm.allRegionParams,
                            false
                          )
                        ),
                        _c("v-input", {
                          attrs: { label: "所在省" },
                          model: {
                            value: _vm.communitySearchParams.province,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "province",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.province",
                          },
                        }),
                        _c("v-input", {
                          attrs: { label: "所在城市" },
                          model: {
                            value: _vm.communitySearchParams.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.communitySearchParams, "city", $$v)
                            },
                            expression: "communitySearchParams.city",
                          },
                        }),
                        _c("v-input", {
                          attrs: { label: "所在区" },
                          model: {
                            value: _vm.communitySearchParams.area,
                            callback: function ($$v) {
                              _vm.$set(_vm.communitySearchParams, "area", $$v)
                            },
                            expression: "communitySearchParams.area",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "项目阶段状态",
                            options: _vm.communityStageOps,
                          },
                          model: {
                            value: _vm.communitySearchParams.communityStage,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "communityStage",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.communityStage",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "项目类型",
                            options: _vm.communityTypeOps,
                          },
                          model: {
                            value: _vm.communitySearchParams.communityType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "communityType",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.communityType",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "项目状态",
                            options: _vm.communityStatusOps,
                          },
                          model: {
                            value: _vm.communitySearchParams.communityStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "communityStatus",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.communityStatus",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }