<!--
 * @Description: 内容发布--资讯帖子--资讯表单页面
 * @Author: 小广
 * @Date: 2019-06-17 18:16:21
 * @LastEditors: 小广
 * @LastEditTime: 2020-05-26 18:49:59
 -->
<template>
  <div class="informationForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <el-form-item
            label="标题"
            prop="title"
            :rules="[
              { required: true, message: '标题不允许为空', trigger: 'blur' }
            ]"
          >
            <v-input
              v-model="form.title"
              placeholder="请输入标题"
              :maxlength="30"
              :width="width"
            ></v-input>
          </el-form-item>
          <el-form-item label="封面图">
            <v-uploader
              :action="uploadURL"
              :imgUrls.sync="form.icon"
              :fileSize="0.5"
              :imageWH="[210, 210]"
            >
              <template #tip>
                <span>注：仅支持宽210px高210px，图片小于512KB</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item
            label="所属App"
            v-if="isAdd"
            :rules="[
              { required: true, message: '所属App不能为空', trigger: 'change' }
            ]"
            prop="appType"
          >
            <v-select
              v-model="form.appType"
              :options="appTypeOps"
              :width="width"
              @change="appTypeChange"
            />
          </el-form-item>
          <el-form-item
            v-else
            label="所属App"
          >
            <span>{{appTypeMap[detailItem.appType]}}</span>
          </el-form-item>
          <el-form-item
            label="类别"
            :rules="[
              { required: true, message: '类别不能为空', trigger: 'change' }
            ]"
            prop="type"
          >
            <v-select
              v-model="form.type"
              :options="typeOps"
              :width="width"
              @change="typeChange"
            />
          </el-form-item>
          <el-form-item
            label="子类别"
            :rules="[
              { required: true, message: '子类别不能为空', trigger: 'change' }
            ]"
            prop="sectype"
          >
            <v-select
              v-model="form.sectype"
              :options="sectypeOps"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            label="排序"
            prop="sort"
            :rules="[
              { required: true, message: '请输入排序', trigger: 'blur' }
            ]"
          >
            <v-input-number
              v-model="form.sort"
              :max="99999999"
              :min="0"
              :width="width"
              placeholder="请输入排序"
              controls
              controlsPosition="right"
            />
          </el-form-item>
          <el-form-item
            label="关键字"
            prop="keywords"
            :rules="[
              {
                type: 'array',
                required: true,
                message: '请输入关键字',
                trigger: 'blur'
              }
            ]"
          >
            <div class="keyword-wrapper">
              <el-tag
                class="tag-keyword"
                closable
                :key="`${keyword}**${index}`"
                v-for="(keyword, index) in form.keywords"
                :disable-transitions="false"
                @close="removeKeyword(index)"
                >{{ keyword }}</el-tag
              >
              <div>
                <v-input
                  class="input-keyword"
                  v-if="inputVisible"
                  v-model="inputValue"
                  ref="saveTagInput"
                  :maxlength="20"
                  placeholder="请输入关键字"
                  :width="width"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm"
                >
                </v-input>
              </div>
              <div>
                <v-button
                  class="input-keyword"
                  v-if="form.keywords.length < 3"
                  @click="showInput"
                  text="+ 添加关键字"
                ></v-button>
              </div>
            </div>
            <div>注：请输入关键字（最多3个），便于信息检索和分享</div>
          </el-form-item>
        </col2-block>
        <col2-block title="资讯信息">
          <el-form-item  label="跳转类型" required>
            <checkbox-plus
              :options="skipTypeOps"
              :value.sync="form.skipType"
            ></checkbox-plus>
          </el-form-item>
          <div class="el-form-div-wrapper">
            <el-form-item
              label="内容"
              prop="content"
              v-if="form.skipType === 1"
              :rules="[
                {
                  required: true,
                  message: '输入内容不能为空',
                  trigger: 'change'
                }
              ]"
            >
              <v-ueditor v-model="form.content" ref="ueditor"></v-ueditor>
              <div v-if="form.appType !== 1" class="choose-jump-wrapper">
                <jump-go  @callback="jumpCallback"></jump-go>
              </div>
            </el-form-item>
          </div>
          <div>
            <el-form-item
              v-if="form.skipType === 2"
              label="跳转页面"
              :rules="[{ required: true,
              validator:jumpNameValidator,
                trigger: 'change' }]"
              prop="skipData"
            >
              <jump-go
                :btnText="jumpName"
                :skipType="2"
                @callback="jumpFunCallback"
              ></jump-go>
            </el-form-item>
          </div>
          <div>
            <el-form-item
              label="外部链接"
              prop="outerUrl"
              v-if="form.skipType === 3"
              :rules="[
                { required: true, message: '请输入外部链接', trigger: 'change' }
              ]"
            >
              <div class="content-wrapper">
                <v-input
                  v-model="form.outerUrl"
                  placeholder="外部链接URL地址"
                  :width="width"
                  :maxlength="200"
                ></v-input>
                <span class="right-tip"
                  >注：请填写以"http://"或"https://"开头的URL地址
                </span>
              </div>
            </el-form-item>
          </div>
          <el-form-item
            v-if="showLabelSelect"
            label="关联组织标签"
            prop="firstOrgTagList"
            :rules="[
              {
                type: 'array',
                required: isAdd,
                message: '关联组织标签不允许为空',
                trigger: 'change'
              }
            ]"
          >
             <checkbox-plus type="default" :options="labelList" mode="checkbox-plus" :value.sync="form.firstOrgTagList" :disabled="!isAdd"/>
          </el-form-item>
          <!-- <el-form-item v-if="!isAdd && form.appType !== 1" label="关联组织标签">
            <span>{{ detailItem.orgTagNames }}</span>
          </el-form-item> -->
          <el-form-item
            v-if="isAdd"
            label="发布项目"
            prop="communityIds"
            :rules="[
              {
                type: 'array',
                required: true,
                message: '发布项目不允许为空',
                trigger: 'change'
              }
            ]"
          >
            <chosenListPanel
              :list.sync="form.communityIds"
              @select="selectCommunityShow = true"
              @change="selectCommunityChange"
            ></chosenListPanel>
          </el-form-item>
          <el-form-item v-else label="发布项目">
            <span>{{ detailItem.communityName }}</span>
          </el-form-item>
        </col2-block>
        <col2-block v-if="!isAdd" title="操作信息">
          <el-form-item
            label="状态"
            :rules="[
              { required: true, message: '请选择状态', trigger: 'change' }
            ]"
            prop="status"
          >
            <v-select
              v-model="form.status"
              :options="statusOps"
              :width="width"
            />
          </el-form-item>
        </col2-block>
        <multi-select
          title="标签列表"
          :isShow.sync="selectTagShow"
          :searchUrl="getOrgTagSearchListURL"
          :headers="orgTagTableHeader"
          :searchParams="orgTagSearchParams"
          :responseParams="orgTagResponseParams"
          :responseKey="responseKey"
          :backFill.sync="form.orgTagIds"
        >
          <template #searchSlot>
            <v-input
              label="标签名称"
              v-model="orgTagSearchParams.tagName"
            ></v-input>
            <v-select2
              label="上级标签"
              v-model="orgTagSearchParams.parentId"
              v-bind="superiorTagParams"
            ></v-select2>
          </template>
        </multi-select>
        <multi-select
          title="项目列表"
          :isShow.sync="selectCommunityShow"
          :searchUrl="getCommunityListURL"
          :headers="communityTableHeader"
          :searchParams="communitySearchParams"
          :responseParams="communityResponseParams"
          :responseKey="responseKey"
          :backFill.sync="form.communityIds"
        >
          <template #searchSlot>
            <v-input
              label="项目名称"
              v-model="communitySearchParams.communityName"
            ></v-input>
            <v-select
              label="区域类型"
              v-model="communitySearchParams.searchRegionType"
              :options="searchRegionTypeOps"
            ></v-select>
            <v-select2
              label="所属区域"
              v-model="communitySearchParams.regionId"
              v-bind="allRegionParams"
              :subjoin="{regionType : communitySearchParams.searchRegionType}"
            ></v-select2>
            <v-input
              label="所在省"
              v-model="communitySearchParams.province"
            ></v-input>
            <v-input
              label="所在城市"
              v-model="communitySearchParams.city"
            ></v-input>
            <v-input
              label="所在区"
              v-model="communitySearchParams.area"
            ></v-input>
            <!-- <v-select2
              label="运营项目组"
              v-model="communitySearchParams.operateRegionId"
              v-bind="operateRegionParams"
            ></v-select2> -->
            <v-select
              label="项目阶段状态"
              v-model="communitySearchParams.communityStage"
              :options="communityStageOps"
            ></v-select>
            <v-select
              label="项目类型"
              v-model="communitySearchParams.communityType"
              :options="communityTypeOps"
            ></v-select>
            <v-select
              label="项目状态"
              v-model="communitySearchParams.communityStatus"
              :options="communityStatusOps"
            ></v-select>
          </template>
        </multi-select>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import {
  createInfoURL,
  editInfoURL,
  queryInfoURL,
  uploadURL,
  getRuleCodeLevelListURL,
  getOrgTagSearchListURL,
  getSuperiorTagListURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL,
  checkSkipCommunityURL,
  getAllFirstLabel
} from './api'
import {
  allRegionParams
} from 'common/select2Params'
import {
  statusOps,
  communityStatusMap,
  communityStatusOps,
  searchRegionTypeOps,
  appTypeMap,
  appTypeOps,
  jumpGoCodeMap
} from './map'
import { vUploader, vUeditor } from 'components/control'
import {
  Col2Detail,
  Col2Block,
  MultiSelect,
  CheckboxPlus,
  ChosenListPanel,
  JumpGo
} from 'components/bussiness'
import { jumpGoCode } from 'components/bussiness/jump-go/code.js'
import Vue from 'vue'
import { Tag } from 'element-ui'
Vue.use(Tag)

export default {
  components: {
    vUploader,
    Col2Detail,
    Col2Block,
    MultiSelect,
    CheckboxPlus,
    ChosenListPanel,
    vUeditor,
    JumpGo
  },
  data () {
    const jumpNameValidator = (rule, value, callback) => {
      let showError = false
      if (!value || value.length === 0) {
        showError = true
      } else {
        let skipData = JSON.parse(value)
        if (!skipData.name || skipData.name.length === 0) {
          showError = true
        }
      }
      showError ? callback(new Error('请选择要跳转的页面')) : callback()
    }
    return {
      jumpNameValidator: jumpNameValidator,
      width: 182,
      uploadURL: uploadURL,
      statusOps: statusOps().slice(0, 2),
      appTypeOps: appTypeOps(),
      appTypeMap: appTypeMap,
      typeOps: [],
      sectypeOps: [],
      allRegionParams,
      inputVisible: false,
      inputValue: '',
      showLabelSelect: false,
      labelList: [],
      form: {
        id: undefined,
        title: '',
        type: undefined,
        sectype: undefined,
        sort: undefined,
        keywords: [],
        status: 1,
        appType: undefined,
        icon: '',
        skipType: 1,
        orgTagIds: [],
        communityIds: [],
        outerUrl: '',
        skipData: '',
        content: '',
        publishType: 1,
        // 修改循环跳转问题，梅森要求添加
        detailId: '',
        firstOrgTagList: []
      },
      detailItem: {},
      submitConfig: {},
      isAdd: false,
      // 是否获取默认的所属话题（取第一个为默认，编辑页面第一次进入的时候不取默认）
      getDefault: true,
      selectTagShow: false,
      getOrgTagSearchListURL: getOrgTagSearchListURL,
      superiorTagParams: {
        searchUrl: getSuperiorTagListURL,
        request: {
          text: 'tagName',
          value: 'tagId'
        },
        response: {
          text: 'tagName',
          value: 'id'
        }
      },
      orgTagSearchParams: {
        tagName: '',
        parentId: ''
      },
      orgTagResponseParams: {
        id: 'id',
        name: 'tagName'
      },
      orgTagTableHeader: [
        {
          prop: 'tagName',
          label: '标签名称'
        },
        {
          prop: 'tagLevel',
          label: '标签层级'
        },
        {
          prop: 'parentName',
          label: '上级标签'
        },
        {
          prop: 'statusName',
          label: '状态'
        }
      ],
      selectCommunityShow: false,
      getCommunityListURL: getCommunityListURL,
      communityStatusOps: communityStatusOps(1),
      searchRegionTypeOps: searchRegionTypeOps(1),
      communityTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatusText',
          label: '项目状态',
          formatter (row) {
            return communityStatusMap[row.communityStatus]
          }
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'provinceName',
          label: '所在省'
        },
        {
          prop: 'cityName',
          label: '所在市'
        },
        {
          prop: 'countryName',
          label: '所在区'
        },
        {
          prop: 'communityPhone',
          label: '项目电话'
        }
      ],
      communitySearchParams: {
        communityName: '',
        searchRegionType: undefined,
        regionId: '',
        province: '',
        city: '',
        area: '',
        operateRegionId: '',
        communityStage: undefined,
        communityType: undefined,
        communityStatus: 0
      },
      operateRegionParams: {
        searchUrl: getOperateRegionsURL,
        request: {
          text: 'regionName',
          value: 'operateRegionId'
        }
      },
      communityResponseParams: {
        id: 'id',
        name: 'communityName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      communityStageOps: [],
      communityTypeOps: [],
      checkParam: {},
      jumpName: '点击选择要跳转的页面',
      appTypeEcho: false,
      typeEcho: false,
      selectCommunityEcho: false
    }
  },

  created () {
    const { id, copy } = this.$route.query
    this.isAdd = id === undefined || copy !== undefined
    this.submitConfig = {
      queryUrl: queryInfoURL,
      submitUrl: this.isAdd ? createInfoURL : editInfoURL,
      submitMethod: this.isAdd ? 'post' : 'put',
      submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
    }
    if (copy !== undefined) {
      this.selectCommunityEcho = true
    }
    let title = this.isAdd ? '添加资讯' : '编辑资讯'
    // 设置title
    this.$setBreadcrumb(title)
  },

  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.appTypeEcho = true
      this.typeEcho = true
      this.$refs.formPanel.getData({ id })
    }
    if (this.isAdd) {
      if (id === undefined) {
        // 新增
        this.form.appType = 0
        this.getRuleCodeLevelList(1, true)
      }
      // 获取选择发布项目相关的数据
      this.getProjectTypes()
      this.getRoomTypes()
    }
    // if (this.isAdd && this.form.appType === 0) {
    //   this.getSuperOrgTagList()
    // }
    this.getFirstLabel()
  },

  computed: {
    skipTypeOps () {
      let ops1 = [
        {
          label: '至详情页',
          value: 1
        },
        {
          label: '至功能页',
          value: 2
        },
        {
          label: '至外部链接',
          value: 3
        }
      ]
      let ops2 = [
        {
          label: '至详情页',
          value: 1
        },
        {
          label: '至外部链接',
          value: 3
        }
      ]

      return this.form.appType === 1 ? ops2 : ops1
    }
  },
  watch: {
    labelList (val) {
      this.showLabelSelect = (val.length > 1)
    }
  },
  methods: {
    update (data) {
      data.status = parseInt(data.status)
      data.keywords = data.keywords || []
      if (data.skipData && data.skipData.length) {
        let skipData = JSON.parse(data.skipData)
        this.jumpName = skipData.name ? skipData.name : this.jumpName
        if (data.skipType === 3) {
          // 取外链地址
          data.outerUrl = skipData.url
        }
      }

      let communityIds = []
      if (data.communityId && data.communityId.length) {
        communityIds = [{
          text: data.communityName,
          id: data.communityId
        }]
      }

      data.communityIds = communityIds

      let orgTagArray = data.orgTagList || []
      let orgTagList = orgTagArray.map(item => {
        return { text: item.name, id: item.id }
      }) || []
      data.orgTagIds = orgTagList
      // if (!data.orgTagIds.length) {
      //   // 如果没有值，就用企业-全部，从接口获取
      //   this.getSuperOrgTagList()
      // }
      data.orgTagNames = orgTagArray.map(item => item.name).join(',') || ''
      // 详情赋值
      this.detailItem = data
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      // 获取类别，不需要默认值
      this.getRuleCodeLevelList(1, false)
    },

    async submitBefore (data) {
      if (data.communityIds && data.communityIds.length) {
        data.communityIds = data.communityIds.map(item => item.id).join(',')
      }

      if (data.orgTagIds && data.orgTagIds.length) {
        data.orgTagIds = data.orgTagIds.map(item => item.id).join(',')
      }

      if (data.keywords && data.keywords.length) {
        data.keywords = data.keywords.join(',')
      }

      if (data.skipType === 1) {
        // 详情
        data.skipData = undefined
      } else if (data.skipType === 2) {
        // 功能页面
        let canSave = true
        if (data.skipData && data.skipData.length) {
          let skipData = JSON.parse(data.skipData)
          if (!skipData.name || skipData.name.length === 0) {
            canSave = false
          }
        } else {
          canSave = false
        }
        if (!canSave) {
          this.$message('请选择需要跳转的页面')
          return false
        }
        data.content = undefined
      } else if (data.skipType === 3) {
        if (data.outerUrl && data.outerUrl.length) {
          let isUrl =
            data.outerUrl.indexOf('https://') ||
            data.outerUrl.indexOf('http://')
          if (!isUrl) {
            this.$message('请填写以"http://"或"https://"开头的URL地址')
            return false
          }
        } else {
          this.$message('请填写外链地址')
          return false
        }
        let skipUrl = {
          pageId: '40000',
          pageType: 4,
          url: data.outerUrl,
          H5NeedAuthorize: false,
          detailId: ''
        }
        data.skipData = JSON.stringify(skipUrl)
        // 外链
        data.content = undefined
      }
      // 这个是本地使用，不上传服务器
      delete data.outerUrl

      if (this.isAdd) {
        // 有可能是copy过来的，不能有id,状态必须是1正常
        data.status = 1
        delete data.id
      }
      if (data.appType === 1) {
        // 管理端APP无组织标签
        data.orgTagIds = ''
      }

      if (data.appType === 1 && data.skipType === 2) {
        this.$message('请选择跳转类型')
        return false
      }

      // 产品要求，在保存的时候，也进行校验
      if (data.skipType === 1) {
        // 这里取content里面的跳转页面数据，若是有，再进行校验
        if (this.dealWithDetailSkipData()) {
          if (data.appType === 1) {
            // 管理端APP
            let message = '保存失败，管理端App无法插入跳转'
            this.$message(message)
            return false
          } else {
            let isOk = await this.checkSkipCommunity()
            if (!isOk) {
              return false
            }
          }
        }
      } else if (data.skipType === 2) {
        if (data.skipData && data.skipData.length) {
          this.checkParam = {}
          let skipData = JSON.parse(data.skipData)
          // 赋值detailId
          data.detailId = skipData.detailId
          // 获取校验参数
          this.dealWithSelectJump(skipData, this.checkParam)
          // 进行校验请求
          let isOk = await this.checkSkipCommunity()
          if (!isOk) {
            return false
          }
        }
      }

      let postData = {
        // dataObject: data.title
      }
      Object.keys(data).forEach(item => {
        if (data[item] !== undefined) {
          postData[item] = data[item]
        }
      })

      return postData
    },
    getSuperOrgTagList () {
      let _this = this
      let superParams = { tagName: '企业' }
      let parentId
      this.$axios.get(getSuperiorTagListURL, { params: superParams }).then(res => {
        if (res.status === 100) {
          if (res.data.length) {
            parentId = res.data[0].id
            _this.setOrgTagId(parentId)
          }
        }
      })
    },
    getFirstLabel () {
      let _this = this
      this.$axios.get(`${getAllFirstLabel}?isPartyTag=${0}`).then(res => {
        if (res.status === 100) {
          res.data.forEach(function (item) {
            _this.labelList.push({
              label: item.tagName,
              value: item.id
            })
          })
        }
      })
    },
    setOrgTagId (parentId) {
      let orgSearchParams = {
        parentId: parentId,
        status: 1,
        executeSearch: 1,
        curPage: 1,
        pageSize: 20
      }
      let _this = this
      this.$axios.get(this.getOrgTagSearchListURL, { params: orgSearchParams }).then(res => {
        if (res.status === 100) {
          let list = res.data.resultList
          if (Array.isArray(list) && list.length) {
            _this.form.orgTagIds = list
          }
        }
      })
    },
    getProjectTypes () {
      this.$axios.get(getProjectTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map(val => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityStageOps = [
            {
              text: '全部',
              value: undefined
            },
            ...ops
          ]
        }
      })
    },
    getRoomTypes () {
      this.$axios.get(getRoomTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map(val => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityTypeOps = [
            {
              text: '全部',
              value: undefined
            },
            ...ops
          ]
        }
      })
    },

    // 插入跳转的回调
    jumpCallback (skipdata, css, html) {
      const editorInstance = this.$refs.ueditor.$refs.ueditorWrap.editor
      editorInstance.focus()
      let content = ''
      if (this.form.content.indexOf('jumpResult') === -1) {
        content = `${css}${html}`
      } else {
        content = html
      }
      editorInstance.execCommand('inserthtml', content)
      this.form.content = editorInstance.getContent()
    },

    jumpFunCallback (skipdata, name) {
      this.jumpName = name
      this.form.skipData = JSON.stringify(skipdata)
      this.$refs.formPanel.validateField('skipData', (errorMessage) => {})
    },

    // 移除关键字
    removeKeyword (index) {
      this.form.keywords.splice(index, 1)
    },

    // 保存关键字tag
    handleInputConfirm () {
      let inputValue = this.inputValue
      if (inputValue) {
        this.form.keywords.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },

    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.focus()
      })
    },

    typeChange (value) {
      if (value !== undefined) {
        this.getRuleCodeLevelList(2, !this.typeEcho)
        if (this.typeEcho) {
          this.typeEcho = false
        }
      } else {
        this.sectypeOps = []
        this.form.sectype = undefined
      }
    },

    appTypeChange (val) {
      // 所属APP改变
      // if (val === 1 && this.form.skipType === 2) {
      //   // 管理端APP 没有跳转至功能页
      //   this.form.skipType = 1
      // }

      this.getRuleCodeLevelList(1, !this.appTypeEcho)
      if (this.appTypeEcho) {
        this.appTypeEcho = false
      } else {
        // 为了解决复选框的bug，暂且强skipType制置为1
        this.form.skipType = 1
      }
    },

    // 获取类别 getDefault是否取第一个为默认值
    // 获取类别
    async getRuleCodeLevelList (level, getDefault) {
      let postData = { codeLevel: level, appType: this.form.appType }
      if (level === 2 && this.form.type && this.form.type.length) {
        // 子类需要传值superId
        postData['superid'] = this.form.type
      }
      let res = await this.$axios.get(getRuleCodeLevelListURL, { params: postData })
      const { data, status } = res
      let typeList = []
      if (status === 100) {
        let list = data || []
        typeList = list.map(item => {
          return {
            text: item.value,
            value: item.id
          }
        }) || []
      }
      if (level === 1) {
        if (getDefault) {
          this.form.type = typeList.length ? typeList[0].value : undefined
        } else {
          if (this.form.type && this.form.type.length) {
            // 融应用兄弟连 B APP2-954 【管理后台】【资讯管理】编辑资讯优化
            // 判断下回显时，对应的type是否在typeList里
            let findItem = typeList.find(item => item.value === this.form.type)
            if (!findItem && this.detailItem.typeInfo) {
              let typeInfo = this.detailItem.typeInfo
              if (typeInfo.id === this.form.type) {
                typeList.push({
                  text: typeInfo.name,
                  value: typeInfo.id
                })
              }
            }
          }
        }
        this.typeOps = typeList
      } else {
        if (getDefault) {
          this.form.sectype = typeList.length ? typeList[0].value : undefined
        } else {
          if (this.form.sectype && this.form.sectype.length) {
            // 融应用兄弟连 B APP2-954 【管理后台】【资讯管理】编辑资讯优化
            // 判断下回显时，对应的sectype是否在typeList里
            let findItem = typeList.find(item => item.value === this.form.sectype)
            if (!findItem && this.detailItem.secTypeInfo) {
              let secTypeInfo = this.detailItem.secTypeInfo
              if (secTypeInfo.id === this.form.sectype) {
                typeList.push({
                  text: secTypeInfo.name,
                  value: secTypeInfo.id
                })
              }
            }
          }
        }
        this.sectypeOps = typeList
      }
    },

    // 选择的项目有改变
    selectCommunityChange (list) {
      if (this.selectCommunityEcho) {
        this.selectCommunityEcho = false
      } else {
        let _this = this
        if (list && list.length) {
        // 在这进行校验
          if (this.form.skipType === 1) {
          //  这里取content里面的跳转页面数据，若是有，再进行校验
            if (this.dealWithDetailSkipData()) {
              // 延时下，解决bug APP2-982
              setTimeout(() => {
                _this.checkSkipCommunity()
              }, 300)
            }
          } else if (this.form.skipType === 2) {
            if (this.form.skipData && this.form.skipData.length) {
              this.checkParam = {}
              let skipData = JSON.parse(this.form.skipData)
              // 获取校验参数
              this.dealWithSelectJump(skipData, this.checkParam)
              // 进行校验请求
              // 延时下，解决bug APP2-982
              setTimeout(() => {
                _this.checkSkipCommunity()
              }, 300)
            }
          }
        }
      }
    },

    // 校验跳转内容是否覆盖项目
    async checkSkipCommunity () {
      if (this.form.skipType === 2) {
        // 至功能页面要处理下
        if (this.form.skipData && this.form.skipData.length) {
          let skipData = JSON.parse(this.form.skipData)
          if (skipData.pageId === '20012') {
            // 代表是资讯详情，服务端自动生成的，不进行校验
            return true
          }
        }
      }

      let communityIds = this.form.communityIds
      let communityList = communityIds.map(item => {
        return {
          communityId: item.id,
          communityName: item.text
        }
      })
      // 处理园区的communityList
      let postData = Object.assign({}, this.checkParam, {
        communityList: communityList
      })
      let res = await this.$axios.post(checkSkipCommunityURL, postData)
      if (res.status === 100) {
        if (res.data && res.data.length > 0) {
          let tempList = res.data.map(item => item.communityName)
          let message = `跳转内容无法覆盖项目【${tempList.join(
            '、'
          )}】，发布之后这些项目点击将无法实现跳转，请确认是否发布`
          let isOk = await this.$confirm(message, { title: '提示', customClass: 'message-info-body' })
          if (!isOk) {
            // 不同意，就要清空所选的园区
            if (!this.isAdd) {
              // 代表是编辑
              if (this.form.skipType === 1) {
                // 至详情页面
                this.form.content = ''
              } else if (this.form.skipType === 2) {
                // 至功能页面
                this.form.skipdata = ''
              }
            } else {
              this.form.communityIds = []
            }
            return false
          }
        }
      }
      return true
    },

    // 根据value找到key
    findKey (obj, value, compare = (a, b) => a === b) {
      return Object.keys(obj).find(k => compare(obj[k], value))
    },

    // 处理至详情页的skipData校验数据
    dealWithDetailSkipData () {
      let div = document.createElement('div')
      div.innerHTML = this.form.content
      let jumpResultList = []
      let dom = div.getElementsByClassName('jumpResult')
      for (let i = 0; i < dom.length; i++) {
        jumpResultList.push({
          pageId: dom[i].attributes.pageid.nodeValue,
          detailId: dom[i].attributes.detailid.nodeValue
        })
      }
      if (jumpResultList.length) {
        this.checkParam = {}
        jumpResultList.forEach(skipData => {
          this.dealWithSelectJump(skipData, this.checkParam)
        })
        return true
      }
      return false
    },
    // 处理跳转内容参数，以便校验跳转的项目覆盖
    dealWithSelectJump (skipData, checkParam) {
      // TOOD 处理选中的参数，选择对应的类型加入checkParam
      if (skipData) {
        // 凤姣说3代表应用
        if (skipData.pageType === 3) {
          // 代表是应用
          if (!checkParam.subIdList) {
            checkParam.subIdList = []
          }
          checkParam.subIdList.push(skipData.detailId)
        } else {
          // 获取类型
          let pageId = skipData.pageId
          let codeKey = this.findKey(jumpGoCode, pageId) || ''
          // 根据codeKey 查询对应参数
          let valueId = jumpGoCodeMap[codeKey]
          if (!checkParam[valueId]) {
            checkParam[valueId] = []
          }
          if (valueId) {
            checkParam[valueId].push(skipData.detailId)
          }
        }
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.informationForm-wrapper {
  .keyword-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .tag-keyword {
      margin-right: 15px;
    }
    .input-keyword {
      margin-right: 15px;
    }
  }

  .el-form-div-wrapper {
    width: 100%;
    display: inline-block;

    .choose-jump-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
      width: 750px;
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: flex-start;

    .right-tip {
      margin-left: 15px;
    }
  }
}
</style>
<style lang="scss">
.message-info-body {
  .el-message-box__message {
    max-height: 400px;
    overflow: auto;
  }
}
</style>
